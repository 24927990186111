import React from 'react';
import { BsGithub, BsYoutube } from 'react-icons/bs';

const SocialMedia = () => {
  return (
    <div className='app__social'>
      <a href="https://github.com/andrewliamc" target='_blank'>
        <div>
          <BsGithub />
        </div>
      </a>
      <a href="https://www.youtube.com/channel/UCf0rvBxHwFxiSyzJ1LpfHZw" target='_blank'>
        <div>
          <BsYoutube />
        </div>
      </a>
    </div>
  )
}

export default SocialMedia;